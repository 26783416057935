import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

export default function Seo(props) {
  const { title = '', description, openGraphImage, robotsNoIndex = false, canonical } = props;
  const router = useRouter();
  const currentPath = router?.asPath ? router.asPath.split('?') : '';
  const canonicalUrl = canonical?.length > 0 ? canonical : `${process.env.NEXT_PUBLIC_SITE_URL}${currentPath[0]}`;
  const ogImage = openGraphImage?.url?.length > 0 ? openGraphImage.url : '/images/meta/default-og-image.png';
  const processOGImage = ogImage.replace(process.env.NEXT_PUBLIC_STORYBLOK_API_IMAGE_CDN, process.env.NEXT_PUBLIC_SITE_URL);
  const formattedTitle = title
    .trim()
    .replace(/[—–-](\s+)?proof(\s+)?(serve)?$/i, '')
    .trim();

  return (
    <Head>
      {robotsNoIndex ? <meta name="robots" content="noindex" /> : null}
      <title>{formattedTitle}</title>
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:title" name="og:title" content={formattedTitle} />
      <meta property="og:description" name="og:description" content={description} />
      <meta property="og:image" name="og:image" content={processOGImage} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@legalproof" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={processOGImage} />

      {canonicalUrl?.length > 0 ? <link rel="canonical" href={canonicalUrl} /> : null}
    </Head>
  );
}
